import React, { useEffect, useState } from "react";
import { getBrightness } from "../utils";
import SAicon from "../assets/sa-icon";

const Skeleton = ({ avatarImg, mainColor, avatarName, children }) => {
	const [step, setStep] = useState(0); // Step controls the appearance of each element
	const [highlight, setHighlight] = useState(false); // Controls highlight animation
	useEffect(() => {
		let timeout;

		if (step < 6) {
			timeout = setTimeout(() => {
				setStep(step + 1);
			}, 800);
		} else {
			timeout = setTimeout(() => {
				setHighlight(true);
			}, 10);
		}

		return () => clearTimeout(timeout);
	}, [step]);

	return (
		<>
			{step >= 1 && (
				<div className="feedback-documents__col skeleton-avatar">
					<div className="assistant-avatar">
						{avatarImg ? (
							<img
								src={avatarImg}
								alt="avatar-img"
								style={{ maxWidth: "40px", maxHeight: "40px" }}
							/>
						) : (
							<SAicon
								circleColor={mainColor}
								color={getBrightness(mainColor) > 155 ? "black" : "white"}
							/>
						)}

						<span>{avatarName ?? "Service Assistant AI"}</span>
					</div>
					{children}
				</div>
			)}
			<div className={`skeleton-wrapper ${highlight ? "highlight" : ""}`}>
				{step >= 2 && (
					<>
						<div className="shimmer-element skeleton short-line"></div>
						<div className="shimmer-element skeleton long-line"></div>
					</>
				)}
				{/* Second part: 3 blocks with circles and lines */}
				{step >= 3 && (
					<div className="skeleton-block">
						<div className="circle shimmer-element"></div>
						<div className="text-lines-wrapper">
							<div
								className="shimmer-element skeleton text-line"
								style={{ width: "10%" }}
							></div>
							<div
								className="shimmer-element skeleton text-line"
								style={{ width: "90%" }}
							></div>
							<div
								className="shimmer-element skeleton text-line"
								style={{ width: "80%" }}
							></div>
						</div>
					</div>
				)}

				{step >= 4 && (
					<div className="skeleton-block">
						<div className="circle shimmer-element"></div>
						<div className="text-lines-wrapper">
							<div
								className="shimmer-element skeleton text-line"
								style={{ width: "10%" }}
							></div>
							<div
								className="shimmer-element skeleton text-line"
								style={{ width: "90%" }}
							></div>
							<div
								className="shimmer-element skeleton text-line"
								style={{ width: "80%" }}
							></div>
						</div>
					</div>
				)}

				{step >= 5 && (
					<div className="skeleton-block">
						<div className="circle shimmer-element"></div>
						<div className="text-lines-wrapper">
							<div
								className="shimmer-element skeleton text-line"
								style={{ width: "10%" }}
							></div>
							<div
								className="shimmer-element skeleton text-line"
								style={{ width: "90%" }}
							></div>
							<div
								className="shimmer-element skeleton text-line"
								style={{ width: "80%" }}
							></div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};
export default Skeleton;
