import React from "react";
import ToolIcon from "../assets/tool";
import QuestionIcon from "../assets/question";
import { useUIText } from "../hooks";

const SelectOnWelcomeChatbot = ({ onClick, cursor, welcomeOptionSelected }) => {
  const uiText = useUIText();
  return (
    <div className="selections-container">
      <div
        className={`option ${welcomeOptionSelected === 2 ? "selected" : ""}`}
        onClick={() => onClick(2)}
        style={{
          cursor: cursor,
        }}
      >
        <div className="option-left">
          <div className="option-icon">
            <QuestionIcon color="#556EA0" />
          </div>
        </div>
        <div className="option-right">
          <div className="option-right__top">
            {uiText.form.openQuestionsTitle}
          </div>
          <div
            className={`option-extra ${
              welcomeOptionSelected === 2 ? "show" : ""
            }`}
          >
            {uiText.form.openQuestionsDescription}
          </div>
        </div>
      </div>

      <div
        className={`option ${welcomeOptionSelected === 1 ? "selected" : ""}`}
        onClick={() => onClick(1)}
        style={{
          cursor: cursor,
        }}
      >
        <div className="option-left">
          <div className="option-icon">
            <ToolIcon color="#556EA0" strokeWidth="1.5" />
          </div>
        </div>
        <div className="option-right">
          <div className="option-right__top">
            {uiText.form.deviceSupportTitle}
          </div>
          <div
            className={`option-extra ${
              welcomeOptionSelected === 1 ? "show" : ""
            }`}
            style={{ maxWidth: "222px" }}
          >
            {uiText.form.deviceSupportDescription}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectOnWelcomeChatbot;
