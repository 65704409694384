import Select from "react-select";
import { formatDeviceName } from "../utils";
import { useUIText } from "../hooks";
import { useEffect, useMemo } from "react";

const AutoSelect = ({
  device,
  items,
  setSelectedDevice,
  menuPlacement = "bottom",
  reset,
  setReset,
  setExampleQuestions,
}) => {
  const uiText = useUIText();
  const devices = useMemo(() => {
    return items
      .map((device) => device.deviceName)
      .map((item) => ({ value: item, label: formatDeviceName(item) }));
  }, [items]);

  useEffect(() => {
    if (reset) {
      setSelectedDevice("");
      setExampleQuestions([]);
    }
  }, [reset]);

  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      isClearable
      isSearchable
      options={devices}
      value={
        device
          ? {
              value: device,
              label: formatDeviceName(device),
            }
          : null
      }
      placeholder={uiText?.form?.deviceModelPlaceholder2}
      onChange={(value) => {
        setSelectedDevice(value?.value || "");
        setReset(false);
      }}
      menuPlacement={menuPlacement}
    />
  );
};

export default AutoSelect;
