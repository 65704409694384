function CloseIcon({ color }) {
	return (
		<svg
			fill={color || "#000000"}
			height="12px"
			width="12px"
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<g>
				<g>
					<polygon
						points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 
			512,452.922 315.076,256 		"
					/>
				</g>
			</g>
		</svg>
	);
}

export default CloseIcon;
