import { useEffect } from "react";
import { ReactComponent as InfoIcon } from "../assets/info.svg";
import { useUIText } from "../hooks";
import * as amplitude from "@amplitude/analytics-browser";
const NoResults = ({ txt, protectionSystem = false, children, noPadding=false}) => {
  useEffect(() => {
    try {
      const identify = new amplitude.Identify().add("Escalation Count", 1);
      amplitude.identify(identify);
    } catch (e) {}
  }, []);
  const uiText = useUIText();
  return (
		<div className={noPadding ? '': 'feedback-instructions'}>
			{children || null}
			<div className={txt === "" ? "no-results" : "no-results custom"}>
				{txt === "" ? (
					<>
						<div className="no-results__icon">
							<InfoIcon />
						</div>
						<div className="no-results__text">
							<div className="no-results__text-title">
								{protectionSystem
									? uiText?.noResults?.protectionAnswer
									: uiText?.noResults?.title}
							</div>
							<div className="no-results__text-subtitle">
								{uiText?.noResults?.subtitle}
							</div>
						</div>
					</>
				) : (
					<div
						// className="feedback-instructions"
						dangerouslySetInnerHTML={{ __html: txt }}
            style={{padding: "0 20px"}}
					/>
				)}
			</div>
		</div>
	);
};

export default NoResults;
