import React, { useState, useEffect } from "react";
import ChatbotApp from "./ChatbotFormContainer";
import FullAccessWelcome from "./full-access/FullAccessWelcome";
import { getApiURL } from "../utils";

const Chatbot = ({
  logo,
  typeOfDemo,
  handleViewChange,
  variables,
  feedbackIsAdded,
  setFeedbackIsAdded,
  isTesting = false,
  mainColor,
}) => {
  const [showFullAccess, setShowFullAccess] = useState(false);
  useEffect(() => {
    if (variables.limitReached) {
      setShowFullAccess(true);
    }
  }, [variables.limitReached]);
  const environment = process.env.REACT_APP_ENV;
  let url = null;
  if (variables.oldAi) {
    if (variables.smaEndpoint) {
      url =
        environment === "production"
          ? "https://ai.service-assistant.ai/SMA_get_instructions"
          : "https://ai.service-assistant-staging.com/SMA_get_instructions";
    } else {
      url =
        environment === "production"
          ? "https://ai.service-assistant.ai/get_instructions"
          : "https://ai.service-assistant-staging.com/get_instructions";
    }
  } else {
    url = `${getApiURL()}/instance/get-instructions`;
  }

  return (
    <div>
      <div className={`customer-layout ${showFullAccess ? "blurred" : ""}`}>
        <ChatbotApp
          variables={variables}
          setFeedbackIsAdded={setFeedbackIsAdded}
          logo={logo}
          typeOfDemo={typeOfDemo}
          handleViewChange={handleViewChange}
          url={url}
          isTesting={isTesting}
          feedbackIsAdded={feedbackIsAdded}
          setShowFullAccess={setShowFullAccess}
          mainColor={mainColor}
        />
      </div>
      {showFullAccess && (
        <div className="full-access-overlay">
          <FullAccessWelcome />
        </div>
      )}
    </div>
  );
};

export default Chatbot;
