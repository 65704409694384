import Switch from "./Switch";
import Logo from "../assets/logo-ver2.svg";
import { useLocation } from "react-router-dom";
import { useContext, useMemo } from "react";
import { AppContext } from "../context";
import { useIsEmbedded } from "../hooks";

const Navbar = ({
  logo,
  typeOfDemo,
  handleViewChange,
  onLogoClick,
  mainColor,
  hasBorder,
}) => {
  const isEmbedded = useIsEmbedded();
  const { instanceInfo } = useContext(AppContext);
  const loc = useLocation();
  const path = loc.pathname;

	const view = useMemo(() => {
		if (['/installer', '/testing/installer'].includes(path)) return "Installer"
		if (['/customer', '/testing/customer'].includes(path)) return "Customer"

		return instanceInfo.typeOfDemo
	}, [path, instanceInfo]);

  if (view === "") return null;
  return (
		<div className={hasBorder ? "navbar with-border" : "navbar"}>
			<div className="navbar-logo">
				<img
					src={logo || Logo}
					alt="Logo"
					onClick={onLogoClick}
					style={{ cursor: "pointer" }}
				/>

				{typeOfDemo === "Both" && !isEmbedded ? (
					<Switch
						view={view}
						handleViewChange={handleViewChange}
						mainColor={mainColor}
					/>
				) : null}
			</div>
		</div>
	);
};

export default Navbar;
