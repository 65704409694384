import React, { useState } from "react";

const OtherQuestionsCard = ({ question, borderColor, onClick, cursor }) => {
  const [cardHovered, setCardHovered] = useState(false);
  return (
    <div
      className="example-question"
      onClick={() => onClick(question.question)}
      style={{
        border: "none",
        color: cardHovered ? borderColor : "#152540",
        cursor: cursor,
        minHeight: "32px",
        // maxHeight: "62px",
        backgroundColor: "#fff",
        boxShadow: "none",
      }}
      onMouseEnter={() => setCardHovered(true)}
      onMouseLeave={() => setCardHovered(false)}
    >
      {question.question}
      <div className="example-question__icon">{">"}</div>
    </div>
  );
};

const OtherQuestionsChatbot = ({
  exampleQuestions,
  onClick,
  borderColor,
  cursor,
}) => {
  return (
    <div className="example-questions other-questions">
      {exampleQuestions.map((question, index) => (
        <OtherQuestionsCard
          key={index + 1}
          question={question}
          borderColor={borderColor}
          onClick={onClick}
          cursor={cursor}
        />
      ))}
    </div>
  );
};

export default OtherQuestionsChatbot;
