function FileIcon({ color }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.58579 1.25253C2.96086 0.877462 3.46957 0.666748 4 0.666748H9.33333C9.51014 0.666748 9.67971 0.736986 9.80474 0.86201L13.8047 4.86201C13.9298 4.98703 14 5.1566 14 5.33341V13.3334C14 13.8638 13.7893 14.3726 13.4142 14.7476C13.0391 15.1227 12.5304 15.3334 12 15.3334H4C3.46957 15.3334 2.96086 15.1227 2.58579 14.7476C2.21071 14.3726 2 13.8638 2 13.3334V2.66675C2 2.13632 2.21071 1.62761 2.58579 1.25253ZM4 2.00008C3.82319 2.00008 3.65362 2.07032 3.5286 2.19534C3.40357 2.32037 3.33333 2.48994 3.33333 2.66675V13.3334C3.33333 13.5102 3.40357 13.6798 3.5286 13.8048C3.65362 13.9298 3.82319 14.0001 4 14.0001H12C12.1768 14.0001 12.3464 13.9298 12.4714 13.8048C12.5964 13.6798 12.6667 13.5102 12.6667 13.3334V5.60956L9.05719 2.00008H4Z"
        fill={color || "#0075CF"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33335 0.666748C9.70154 0.666748 10 0.965225 10 1.33341V4.66675H13.3334C13.7015 4.66675 14 4.96523 14 5.33342C14 5.7016 13.7015 6.00008 13.3334 6.00008H9.33335C8.96516 6.00008 8.66669 5.7016 8.66669 5.33342V1.33341C8.66669 0.965225 8.96516 0.666748 9.33335 0.666748Z"
        fill={color || "#0075CF"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66669 8.66667C4.66669 8.29848 4.96516 8 5.33335 8H10.6667C11.0349 8 11.3334 8.29848 11.3334 8.66667C11.3334 9.03486 11.0349 9.33333 10.6667 9.33333H5.33335C4.96516 9.33333 4.66669 9.03486 4.66669 8.66667Z"
        fill={color || "#0075CF"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66669 11.3334C4.66669 10.9652 4.96516 10.6667 5.33335 10.6667H10.6667C11.0349 10.6667 11.3334 10.9652 11.3334 11.3334C11.3334 11.7016 11.0349 12.0001 10.6667 12.0001H5.33335C4.96516 12.0001 4.66669 11.7016 4.66669 11.3334Z"
        fill={color || "#0075CF"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66669 5.99992C4.66669 5.63173 4.96516 5.33325 5.33335 5.33325H6.66669C7.03488 5.33325 7.33335 5.63173 7.33335 5.99992C7.33335 6.36811 7.03488 6.66658 6.66669 6.66658H5.33335C4.96516 6.66658 4.66669 6.36811 4.66669 5.99992Z"
        fill={color || "#0075CF"}
      />
    </svg>
  );
}

export default FileIcon;
