import { ReactComponent as WarningIcon } from "../assets/alert-triangle.svg";
const Warning = ({ warningText }) => {
	const sentences = warningText.split("\n");
	const note = sentences.pop();
	const warningMessage = sentences.join("\n");
	return (
		<div className="feedback-warning">
			<div
				style={{ paddingRight: "8px", display: "flex", alignItems: "center" }}
			>
				<WarningIcon />
			</div>

			<div>
				{warningMessage}
				<div style={{ color: "#906557", lineHeight: '18px' }}>{note}</div>
			</div>
		</div>
	);
};

export default Warning;
