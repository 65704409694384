function WebIcon({ color }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path
				d="M12.4 22.75C6.47002 22.75 1.65002 17.93 1.65002 12C1.65002 6.07 6.47002 1.25 12.4 1.25C18.33 1.25 23.15 6.07 23.15 12C23.15 17.93 18.33 22.75 12.4 22.75ZM12.4 2.75C7.30002 2.75 3.15002 6.9 3.15002 12C3.15002 17.1 7.30002 21.25 12.4 21.25C17.5 21.25 21.65 17.1 21.65 12C21.65 6.9 17.5 2.75 12.4 2.75Z"
				fill={color || "#5A55AB"}
			/>
			<path
				d="M9.40001 21.75H8.40001C7.99001 21.75 7.65001 21.41 7.65001 21C7.65001 20.59 7.97001 20.26 8.38001 20.25C6.81001 14.89 6.81001 9.11 8.38001 3.75C7.97001 3.74 7.65001 3.41 7.65001 3C7.65001 2.59 7.99001 2.25 8.40001 2.25H9.40001C9.64001 2.25 9.87001 2.37 10.01 2.56C10.15 2.76 10.19 3.01 10.11 3.24C8.23001 8.89 8.23001 15.11 10.11 20.77C10.19 21 10.15 21.25 10.01 21.45C9.87001 21.63 9.64001 21.75 9.40001 21.75Z"
				fill={color || "#5A55AB"}
			/>
			<path
				d="M15.4001 21.7502C15.3201 21.7502 15.2401 21.7402 15.1601 21.7102C14.7701 21.5802 14.5501 21.1502 14.6901 20.7602C16.5701 15.1102 16.5701 8.89018 14.6901 3.23018C14.5601 2.84018 14.7701 2.41018 15.1601 2.28018C15.5601 2.15018 15.9801 2.36018 16.1101 2.75018C18.1001 8.71018 18.1001 15.2702 16.1101 21.2202C16.0101 21.5502 15.7101 21.7502 15.4001 21.7502Z"
				fill={color || "#5A55AB"}
			/>
			<path
				d="M12.4 17.1998C9.61002 17.1998 6.83002 16.8098 4.15002 16.0198C4.14002 16.4198 3.81002 16.7498 3.40002 16.7498C2.99002 16.7498 2.65002 16.4098 2.65002 15.9998V14.9998C2.65002 14.7598 2.77002 14.5298 2.96002 14.3898C3.16002 14.2498 3.41002 14.2098 3.64002 14.2898C9.29002 16.1698 15.52 16.1698 21.17 14.2898C21.4 14.2098 21.65 14.2498 21.85 14.3898C22.05 14.5298 22.16 14.7598 22.16 14.9998V15.9998C22.16 16.4098 21.82 16.7498 21.41 16.7498C21 16.7498 20.67 16.4298 20.66 16.0198C17.97 16.8098 15.19 17.1998 12.4 17.1998Z"
				fill={color || "#5A55AB"}
			/>
			<path
				d="M21.4001 9.74986C21.3201 9.74986 21.2401 9.73986 21.1601 9.70986C15.5101 7.82986 9.28005 7.82986 3.63005 9.70986C3.23005 9.83986 2.81005 9.62986 2.68005 9.23986C2.56005 8.83986 2.77005 8.41986 3.16005 8.28986C9.12005 6.29986 15.6801 6.29986 21.6301 8.28986C22.0201 8.41986 22.2401 8.84986 22.1001 9.23986C22.0101 9.54986 21.7101 9.74986 21.4001 9.74986Z"
				fill={color || "#5A55AB"}
			/>
		</svg>
	);
}

export default WebIcon;
