import React, { useEffect, useRef, useState } from "react";

const ExampleQuestions = ({
  exampleQuestions,
  onClick,
  borderColor,
  cursor,
  isChat = false,
}) => {
  const Card = ({ question, index }) => {
    const [cardHovered, setCardHovered] = useState(false);
    const questionRef = useRef(null);

    return (
      <div
        key={index}
        className="example-question"
        onClick={() => onClick(question)}
        style={{
          borderColor: borderColor,
          cursor: cursor,
          minHeight: "32px",
          color: cardHovered ? borderColor : "#152540",
        }}
        ref={questionRef}
        onMouseEnter={() => setCardHovered(true)}
        onMouseLeave={() => setCardHovered(false)}
      >
        {question}
      </div>
    );
  };

  const questionRefs = useRef([]);

  useEffect(() => {
    if (questionRefs.current.length) {
      // Reset all heights before recalculating
      questionRefs.current.forEach((ref) => {
        if (ref) {
          ref.style.height = "auto";
        }
      });

      let maxHeight = 0;

      // Find the tallest question
      questionRefs.current.forEach((ref) => {
        if (ref) {
          maxHeight = Math.max(maxHeight, ref.clientHeight);
        }
      });

      // Set all questions to the height of the tallest question
      questionRefs.current.forEach((ref) => {
        if (ref) {
          ref.style.height = `${maxHeight}px`;
        }
      });
    }
  }, [exampleQuestions]);

  return (
    <div className="example-questions">
      {exampleQuestions.slice(0, 4).map((question, index) => (
        <Card question={question} index={index} />
      ))}
    </div>
  );
};

export default ExampleQuestions;
