import { useContext } from "react";
import { AppContext } from "../context/index";
import * as amplitude from "@amplitude/analytics-browser";
import { sendQuestionData, sendUserFeedback } from "../utils";
export function useTrackSourceClicked() {
  const { instanceInfo, userProperties } = useContext(AppContext);
  /**
   * @param {string} type
   */
  return (type) => {
    try {
      amplitude.track("source_clicked", {
        "Session ID": userProperties["Session ID"],
        "Instance ID": process.env.REACT_APP_INSTANCE_ID,
        "Company Name": instanceInfo.companyName,
        "Instance Name": instanceInfo.name,
        "Source Type": type,
      });
    } catch (e) {}
  };
}

export function useTrackEscalation() {
  const { userProperties, instanceInfo } = useContext(AppContext);
  /**
   *@param {string} type
   */
  return (type) => {
    try {
      amplitude.track("escalation_triggered", {
        "Session ID": userProperties["Session ID"],
        "Instance ID": process.env.REACT_APP_INSTANCE_ID,
        "Escalation Type": type,
        "Company Name": userProperties["Company Name"],
        "Instance Name": instanceInfo.name,
      });
    } catch (e) {}
  };
}
export function useSendQuestionData() {
  return async (
    userInput,
    answer,
    sourceDocument = "",
    sourcePages = [],
    sessionId = null,
    conversationId = null,
    error = false,
    emptyAnswer = true,
    otherQuestionsEnabled = false
  ) => {
    try {
      const identify = new amplitude.Identify()
        .add("Questions Asked", 1)
        .set(
          "Device Details",
          otherQuestionsEnabled ? "general" : userInput.device
        );
      amplitude.identify(identify);
    } catch (e) {}

    return sendQuestionData(
      userInput,
      answer,
      sourceDocument,
      sourcePages,
      sessionId,
      conversationId,
      error,
      emptyAnswer,
      otherQuestionsEnabled
    );
  };
}
export function useSendUserFeedback() {
  const { userProperties } = useContext(AppContext);
  return async (recordId, positive, conversationId, feedback) => {
    return sendUserFeedback(
      recordId,
      positive,
      conversationId,
      feedback,
      userProperties["Session ID"]
    );
  };
}
export function useTrackNewQuestionClicked() {
  const { instanceInfo, userProperties } = useContext(AppContext);
  return () => {
    try {
      amplitude.track("new_question_clicked", {
        "Session ID": userProperties["Session ID"],
        "Instance ID": process.env.REACT_APP_INSTANCE_ID,
        "Company Name": instanceInfo.companyName,
        "Instance Name": instanceInfo.name,
      });
    } catch (e) {}
  };
}
